.careers {
	/*background: rgba(237, 237, 237, 0.3);*/
	background: url(/public/career1.png) no-repeat;
	background-size: cover;
	background-position: center;
	z-index: -1;
	position: relative;
}

.careerform {
	align-content: center;
}

.banner-info2 {
	height: auto;
	text-align:center;
}
.banner-info2 h1 {
	font-size: 50px;
	font-weight:900;
	color: #fff;
	margin-top:40px;
}
.banner-info2 h6 {
	color: #fff;
	line-height: 26px;
	font-weight:500;
	margin-bottom:0rem;
}
.rotate2-180 {
	transform: rotate(180deg);	
}
.rotate2-180 svg{
	margin: -4px 0 0 0;
}
.about p {
	color: #777;
	line-height: 26px;
}

.about-img {
	height: 500px;
	padding-right: 100px !important;
	position: relative;
	width: 100%;
	z-index: 0;
}

.about-img:after {
	content: '';
	position: absolute;
	background: rgba(45, 158, 218, 0.4);
	left: 1rem;
	top: 1rem;
	right: -1rem;
	bottom: -1rem;
	z-index: -1;
	margin-right: 100px;
}

.about-img img {
	height: 500px;
	width: 100%;
	object-fit: cover;
}


@media (max-width: 1199.98px) {
	.about-img {
		padding-right: 50px !important;
	}

	.about-img::after {
		margin-right: 50px !important;
	}
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
	.about-img {
		padding-right: 50px !important;
		padding-left: 50px;
		margin-bottom: 50px;
		height: 300px;
	}
	.about-img::after {
		margin-right: 50px !important;
		margin-left: 50px
	}
	.about-img img {
		height: 300px;
	}
	.about {
		padding: 20px 0;
	  }
	
}

@media (max-width: 575.98px) {
	.about-img {
		padding-right: 20px !important;
		padding-left: 20px;
		margin-bottom: 50px;
		height: 250px;
	}
	.about-img::after {
		margin-right: 20px !important;
		margin-left: 20px
	}
	.about-img img {
		height: 250px;
	}
	.about p {
		line-height: 20px;
		font-size: 14px;
	  }
}

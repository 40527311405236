.footer-top {
	padding:30px 0;
	background: rgba(237, 237, 237, 0.3);
	color: #777;
	font-weight:300;
}
.footer-top p {
	margin: 0;
}
.footer-bottom {
	background: #2D9EDA;
	padding:20px 0;
	color: #fff;
	font-weight:300;
	font-size:14px;
}
.sc {
	color: #212529;
}
.footer {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	overflow-x: hidden;
}


@media (max-width: 575.98px) {
	.footer-top {
		padding: 15px 0;
	  }
.footer-top h5{
	font-size: 18px;
}
.footer-top p{
	font-size: 14px;
}
}
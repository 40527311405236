@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


/* .contact-section{
	background-repeat:repeat-x;
	padding:25px 0 25px;

}
.contact-right p{
	font-family: 'Roboto', sans-serif;
	letter-spacing: .5px;
}

.contact-left{
	padding:0 50px;
} */
/* .contact-section input.form-control{
	border-radius:0;
	border:2px solid #eceef2; 
	color: #8799a3;
	font-size: 16px;
	padding: 25px;
	height: 50px;
} */
/* .contact-section textarea.form-control{
	border-radius:0;
	border:2px solid #eceef2; 
	color: #8799a3;
	font-size: 16px;
	padding: 25px;
} */

.locate{
	padding:0;
	margin:3rem 0;
}
.locate li{
	margin-bottom:1.5rem;
	font-size:18px;
	font-weight:300;
	list-style:none;
	display:flex;
	
}
.locate li span{
	font-size:24px;
	min-width:50px;
}
.social-links{
	padding:0;
	margin:0;
}
.social-links li{	
	display:inline-block;
	margin:0 1rem;
}
.social-links li a{
	color:#3057A6;
	font-size:24px;
}
.social-links li a:hover{
	color: #C92090;
}
.btn_contactsubmit{
	float:left;
	border-style:double;
	border-color: rgb(25, 66, 180);
	background-color: white;
	color:rgb(25, 66, 180);
	padding:8px 48px;
	cursor:pointer;;
}

.btn_contactsubmit:hover{
	background-color: rgb(25, 66, 180);
	color: rgb(250, 253, 255);
}
/* .contact-dd {
	border: none;
	padding:1rem;
	width: 300px;
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
} */

.navbar-collapse .nav-item .nav-link {
	margin: 0;
   }


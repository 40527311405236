@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
	scrollbar-width: thin;
	scroll-behavior: smooth;
}

.scroll-to-top {
	position: fixed;
	bottom: .5rem;
	right: 1rem;
	background: #fff;
	width: 40px;
	height: 40px;
	display: block;
	z-index: 1020;
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	opacity: 0;
	box-shadow: 0 0px 15px 0 rgba(0, 0, 0, .15);
}

.scroll-to-top.active {
	opacity: 1;
}

body {
	font-family: 'Poppins', sans-serif !important;
	font-size: 16px;
	color: #444;
	overflow-x: hidden;
}

.text-danger { 
	font-size: 13px !important;
}

label {
	font-size: 13px;
}

.about, .services, .career, .client, .contact {
	padding: 50px 0;
}

.btn-blue, .btn-blue:hover, .btn:first-child:active {
	color: #fff;
	border: 1 solid #2D9EDA;
	background-color: #2D9EDA;
 	padding:.5rem 2rem;
}

.shrink {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	background: #fff;
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
}

.home-msg {
	margin-top: -150px;
	margin-bottom: 50px;
	background:#fff;
	padding:2rem;
	font-size:24px;
	font-weight:600;
	text-align:center;
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
}
.home-msg .carousel-indicators {
	position: relative;
	margin-bottom: 0;
	margin-top: 2rem;
}
.home-msg .carousel-indicators [data-bs-target] {
	background-color:#2D9EDA;
	width: 20px;
	height: 8px;
	margin-right: 5px;
	margin-left: 5px;
	border: none;
	border-radius: 10px;
}
.home-msg .carousel-indicators .active {
	opacity: 1;
	width: 8px;
	height: 8px;
}
.client-carousel{
	padding: 0 50px;
}
.slick-prev {
	left: -10px !important;
  }
.slick-next {
	right: -10px !important;
  }
  .text-grey {
	color: #212529;
  }

@media (max-width: 1199.98px) {
	.banner-bottom {
		margin: -100px 0 0 0;
	}

	.about-img {
		padding-right: 0px;
	}

	.about-img::after {
		margin-right: 0px;
	}

	.banner-img {
		max-height: 85%;
	}
}

@media (max-width: 991.98px) {
	.navbar-collapse {
		border-top: 1px solid #2D9EDA;
		margin-top: 1.5rem;
	}

	.navbar-collapse .nav-item {
		margin: .75rem 0;
	}

	.navbar-collapse .nav-item .nav-link {
		margin: 0;
	}

	.banner-bottom {
		margin: -80px 0 0 0;
	}

	.banner-info h1 {
		font-size: 70px;
	}

	.banner-img {
		max-height: 75%;
		right: 1rem;
	}
}

@media (max-width: 767.98px) {
	.home-msg {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.banner-bottom {
		margin: -60px 0 0 0;
	}

	.banner-info h1 {
		font-size: 60px;
	}

	.banner-img {
		max-height: 62%;
	}
	.slick-prev {
		left: -40px !important;
	  }
	.slick-next {
		right: -40px !important;
	  }
}

/* error in third class css of media */
@media (max-width: 575.98px) {	

	.contact-list li {
		display: inline-block;
		margin: .6rem .5rem;
		font-size: 12px;
		color: #fff;
		font-weight: 500;
	}

	.contact-list li i {
		margin-right: .25rem;
		font-size: 16px;
		vertical-align: middle;
	}

	.social-list li {
		display: inline-block;
		margin: .1rem .5rem;
	}
}

/* *{
    margin:0;
    padding:0;
} */
.page-title {
	font-weight: 600;
	margin-bottom: 50px;
	position: relative;
}

.page-title:after {
	content: '';
	background: #2D9EDA;
	height: 6px;
	width: 60px;
	position: absolute;
	border-radius: 16px;
	left: 0;
	bottom: -1rem;
}

.page-title.center:after {
	right: 0;
	margin: auto;
}

.btn-blue,
.btn-blue:hover,
.btn:first-child:active {
	color: #fff !important;
	border: 1 solid #2D9EDA !important;
	background-color: #2D9EDA !important;
	padding: .5rem 2rem !important;
}
.service-info {
	background: #fff;
	padding: 2rem;
	height: 100%;
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
}

.service-info h4 {
	color: #2D9EDA;
	margin-bottom: 1rem;
	font-weight: 200;
	font-size: 22px;
}

.service-info h5 {
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 1rem;
}

.service-info p {
	color: #777;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0;
	text-align: justify;
}
.header-top {
	overflow-x:hidden;
}

.contact-list {
	padding: 0 20rem 0 0;
	margin: 0;
	position:relative;
	z-index:10;
}

.contact-list li {
	display: inline-block;
 	margin: .75rem 1rem;
	font-size: 16px;
	color: #fff;
	font-weight:500;
}

.contact-list li i {
 margin-right: .5rem;
	font-size:20px;
	vertical-align:middle;
}

.contact-list:after {
	content: '';
	background:#2D9EDA;
	right:0;
	height:100%;
	transform: skewX(-25deg);
	position:absolute;
	width:100vw;
	z-index:-1;
}

.social-list {
	padding: 0 0 0 1rem;
	margin: 0;
	display: inline-block;
	position:relative;
}
.social-list li {
	display: inline-block;
 	margin:.5rem .5rem;
}
.social-list li a {
	font-size: 18px;
	color: #ffffff;
	width: 32px;
	height: 32px;
	display: block;
	border-radius: 30px;
	line-height: 36px;
	text-align: center;
}
.social-list li a:hover {
	background: rgba(255, 255, 255, 0.2);
}
.social-list:after {
	content: '';
	background:#2D9EDA;
	left:0;
	height:100%;
	transform: skewX(-25deg);
	position:absolute;
	width:100vw;
	z-index:-1;
}

.header-nav {
	box-shadow: 0 1.2rem 1rem rgba(0,0,0,0.03),inset 0 -1px 0 rgba(255,255,255,0.15);
}

.header-nav .navbar {
	padding: 1rem 0;
	background:#fff;
}

.header-nav .nav-link {
	font-size:16px;
	font-weight:600;
	color: #444;
	padding: 0 1.5rem !important;
}
.header-nav .nav-link.active {
	color: #2D9EDA;
}
.navbar-brand img {
	height: 40px;
}
.navbar-toggler {
	border: none !important;
	padding: 0 !important;
}
.navbar-toggler:focus {
	text-decoration: none !important;;
	outline: 0 !important;
	box-shadow: 0 0 0 0 !important;
}

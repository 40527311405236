
.products {
	background: url(/public/career.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	z-index: -1;
	position: relative;
}

.product-logo {
	height: 40px;
	margin-bottom: 1rem;
}
.rotate-180 {
	transform: rotate(180deg);
	height: 80px;
}

.client {
	font-size: 16px;
	color: #444 !important;
	font-weight: 300;
	margin-bottom: 40px;
}

.client-img {
	padding: 1rem;
}
.client-img img {
	height: 60px;
	object-fit: contain;
}

.product-info {
	background: #fff;
	padding: 2rem;
	height: 100%;
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
}

.product-info h4 {
	color: #2D9EDA;
	margin-bottom: 1rem;
	font-weight: 200;
	font-size: 22px;
}
.product-info h5 {
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 1rem;
}
.product-info p {
	color: #777;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0;
	text-align: justify;
}

.client-img {
    padding: 1rem;
}

.client-img img {
    display: block;
    height: 60px;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
    margin: 0px auto;
    margin-bottom: 20px;
	width: 100%;
}
.slick-prev:before, .slick-next:before {
    line-height: 0 !important;
    color: #2D9EDA !important;
}
.container-xl {
    padding-right: calc(var(--bs-gutter-x) * 2);
    padding-left: calc(var(--bs-gutter-x) * 2);
}

.products svg{
	margin: -5px 0 0 0;
}
.banner {
    background: url(/public/bg.jpg) no-repeat;
    background-size: cover;
    min-height: calc(100vh - 136px);
    z-index: -1;
    position: relative;   
}
.banner-info {
    min-height: calc(100vh - 136px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0;
}
.banner-info h1 {
    font-size: 80px;
    font-weight: 900;
    color: #fff;
}
.banner-info h6 {
    color: #fff;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 3rem;
    font-size: 18px;
}
.banner2 {
	/*background:url(../img/bg-3.jpg) no-repeat;*/
	background-size: cover;
	min-height: 350px;
	z-index: -1;
	position: relative;
}
.banner-bottom {
	margin: -134px 0 0 0;
	z-index:-1;
	position:relative;
}
.banner-img {
	margin-top: 4rem;
	/*height:calc(100vh - 136px - 4rem);*/
	max-height:90%;
	position:absolute;
	right:3rem;
	z-index:-1;
	bottom: 0;
}
.banner-img2 {
	margin-top: 4rem;
	height:250px;
	position:absolute;
	right:3rem;
	z-index:-1;
}


.banner-info2 {
	height: auto;
	text-align:center;
}
.banner-info2 h1 {
	font-size: 50px;
	font-weight:900;
	color: #fff;
	margin-top:40px;
}
.banner-info2 h6 {
	color: #fff;
	line-height: 26px;
	font-weight:500;
	margin-bottom:0rem;
}

.banner-bottom {
    margin: -134px 0 0 0;
    z-index: -1;
    position: relative;
}

.carousel-item {
    font-family: 'Poppins', sans-serif ;
    color: #444;
}
.home-msg {
    margin-top: -100px;
    margin-bottom: 50px;
    background: #fff;
    padding: 2rem;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45);
}
.home-msg .carousel-indicators {
    position: relative;
    margin-bottom: 0;
    margin-top: 2rem;
}
.home-msg .carousel-indicators [data-bs-target] {
    background-color: #2D9EDA;
    width: 20px;
    height: 8px;
    margin-right: 5px;
    margin-left: 5px;
    border: none;
    border-radius: 10px;
}
.home-msg .carousel-indicators .active {
    opacity: 1;
    width: 8px;
    height: 8px;
}
@media (max-width: 1199.98px) {
    .banner-bottom {
        margin: -100px 0 0 0;
    }
    .about-img {
        padding-right: 0px;
    }
    .about-img::after {
        margin-right: 0px;
    }
    .banner-img {
        max-height: 85%;
    }
    .banner, .banner-info {       
        min-height: auto !important;
    }
}
@media (max-width: 991.98px) {
    .navbar-collapse {
        border-top: 1px solid #2D9EDA;
        margin-top: 1.5rem;
    }
    .navbar-collapse .nav-item {
        margin: .75rem 0;
    }
    .navbar-collapse .nav-item .nav-link {
        margin: 0;
    }
    .banner-bottom {
        margin: -80px 0 0 0 !important;
    }
    .banner-info h1 {
        font-size: 70px;
    }
    .banner-img {
        max-height: 75%;
        right: 1rem;
    }
   
    
}
@media (max-width: 767.98px) {
    .home-msg {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .banner-bottom {
        margin: -60px 0 0 0 !important;
    }
    .banner-info h1 {
        font-size: 60px;
    }
    .banner-img {
        max-height: 62%;
    }
}
/* display: flex  --> is giving warning in terminal so commented */
@media (max-width: 575.98px) {
    .banner-img {
        max-height: 300px;
    }
    .banner-info {        
        padding: 50px 0;
    }
    .banner-info h1 {
        font-size: 48px;
    }    
    .banner-info h6 {
        font-size: 14px;
    }
    .banner-bottom {
        margin: -40px 0 0 0 !important;
    }
    .contact-list li {
        display: inline-block;
        margin: .6rem .5rem;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
    }
    .contact-list li i {
        margin-right: .25rem;
        font-size: 16px;
        vertical-align: middle;
    }
    .social-list li {
        display: inline-block;
        margin: .1rem .5rem;
    }
   
}